<template>
    <div class="RoleCreate">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="role" label-width="80px" :rules="rules" size="small">
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="role.name" />
                </el-form-item>
                <el-form-item label="角色类型" prop="type">
                    <el-radio-group v-model.number="role.type">
                        <el-radio :label="1">业务型角色</el-radio>
                        <el-radio :label="0">管理型角色</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="角色范围" prop="type">
                    <el-radio-group v-model.number="role.openType">
                        <el-radio :label="1">私有</el-radio>
                        <el-radio :label="0">公开</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="归属类型" prop="deptType">
                    <el-select v-model.number="role.deptType">
                        <el-option label="门店" :value="3" />
                        <el-option label="配送中心" :value="2" />
                        <el-option label="总部" :value="1" />
                    </el-select>
                </el-form-item>
                <el-form-item label="权重" prop="weight">
                    <el-input v-model.number="role.weight" placeholder="大于基本等级30" style="width: 220px" />
                </el-form-item>
                <ef-remark v-model="role.remark" />
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import EfRemark from 'components/EfRemark';

export default {
    name: 'RoleCreate',
    components: { EfRemark },
    data() {
        return {
            role: {
                name: '',
                weight: '',
                remark: '',
                openType: 1,
                deptType: 3,
                type: 1,
            },
            rules: {
                name: [{ required: true, min: 1, max: 10, message: '名称必填,1到10个字', trigger: 'blur' }],
                weight: [
                    { required: true, type: 'number', min: 30, message: '权重必填,大于基本等级30', trigger: 'blur' },
                ],
                remark: [{ required: true, max: 200, message: '备注必填且字数不能超过200', trigger: 'blur' }],
            },
            url: '/system/role/create',
        };
    },

    methods: {
        handleSave() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                UrlUtils.PostRemote(_this, this.url, this.role);
            });
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}

.el-textarea {
    width: auto;
}
</style>
